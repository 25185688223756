import forList from "@/mixin/forList";
import axios from 'axios';
import { Loading } from 'element-ui';

// 生成一个随机数字 默认6位数
function createNumber(count = 6) {
    return Math.floor(Math.random() * (10 ** count))
}

export default {
    name: "giftActivityDetail",
    mixins: [forList],
    noSaveState: 1,

    data() {
        return {
            actId: '',
            editDialog: false,
            actEditData: {
                // actName: '',
                cancelRemark: '',
                // id: '',
            },
            editRules: {
                cancelRemark: { required: true, message: "请输入取消赠礼理由", trigger: "blur" },
            },

            addDialog: false,
            actData: {
                actName: '',
                actStartTime: '',
                actEndTime: '',
                file: [],
                remark: '',
            },
            rules: {
                // actName: { required: true, message: "请输入活动名称", trigger: "blur" },
                // cusfile: { required: true, message: "请上传客户表格", trigger: "blur" },
                // xzffqkh: { required: true, message: "请选择发劵客户", trigger: "blur" },
                // zshdmc: { required: true, message: "请查询选择活动名称", trigger: "blur" },
                // none: {
                //     validator: function (r, v, cb) {
                //         cb();
                //     },
                // },
            },
            // giftActivityCustomerTemplate
            // sampleFileUrl: this.$baseURL + "upload/winktemp/custcoupontemp.xlsx",
            sampleFileUrl: this.$baseURL + "upload/winktemp/giftActivityCustomerTemplate.xlsx",



            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                custCode: '',
                empCode: '',
                isGive: '',
                proxy: '',

                // actId

                // actName: '',
                // searchType: 1,    // 搜索类型
                // searchValue: "",   // 搜索类容
                // searchState: "",   // 状态
                // searchDraw: "",    // 是否已领取
                // startTime: "",  // 开始时间
                // endTime: "",    // 结束时间
            },
            exporting: false,      // 导出中
            // pageFlag: 0,           // 保存一个随机数字，在获取列表数量前随机生成，在请返回时验证是否是最新一次请求

            //#region 选项配置
            searchTypeList: [      // 查询条件
                { value: 1, name: "赠礼活动名称" },
            ],
            drawTypeList: [
                { value: 1, name: "是" },
                { value: 0, name: "否" }
            ],
            stateList: [           // 状态
                { value: 0, name: "失效" },
                { value: 1, name: "可使用" },
                { value: 2, name: "已使用" },
                { value: 3, name: "已作废" },
                { value: 5, name: "待解锁" }
            ],
            //#endregion
        };
    },

    computed: {
        buttonFreezing() {
            return this.loading || this.operating
        },

        bulkFreezing() {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {
        // 点击返回
        handleNavigationBack() {
            this.$router.back();
        },

        handleAdd() {
            this.addDialog = true;
        },

        handleClose() {
            this.addDialog = false;
        },


        handleExceed() {
            this.$message({
                message: "只能上传一个表格",
                type: "error"
            })
        },
        handleSelectFile(e) {
            this.actData.file.push(e.raw)
        },
        handleRemoveFile(e) {
            const index = this.actData.file.indexOf(e);
            this.actData.file.splice(index, 1)
        },
        handleBulkIssue_old() {
            // let loadingRef;
            // this.$refs.issue.validate()
            //     .then(() => {
            //         loadingRef = Loading.service({
            //             target: ".el-dialog"
            //         })
            //         const data = new FormData();
            //         const { cusfile, couponConfId, remark } = this.issueData;
            //         data.append("cusfile", cusfile[0]);
            //         data.append("couponConfId", couponConfId);
            //         data.append("remark", remark);
            //         return axios.post(this.$baseURL + "api/custCoupon/batchGiftCustCoupon", data, {
            //             headers: {
            //                 "Content-Type": "multipart/form-data",
            //                 token: this.$store.state.token
            //             }
            //         })
            //     })
            //     .then(res => {
            //         if (res.data.code === 2000) {

            //             this.$alert(res.data.msg || "发放成功", "批量发放结果");
            //             this.issueData = null;
            //             loadingRef && loadingRef.close();

            //         } else if (res.data?.code === 1003) {
            //             this.$store.commit("clearUserInfo");
            //             this.$message({
            //                 message: "登录过期，请重新登录",
            //                 type: "warning"
            //             });
            //             return Promise.reject(false);

            //         } else return Promise.reject(res.data);
            //     })
            //     .catch(reason => {
            //         console.warn("上传失败", reason);
            //         if (reason !== false) {
            //             this.$message({
            //                 message: reason?.msg || "发放失败",
            //                 type: "error"
            //             })
            //         }
            //         loadingRef && loadingRef.close();
            //     })
        },
        handleBulkIssue() {
            let loadingRef;
            this.$refs.issue.validate()
                .then(() => {
                    loadingRef = Loading.service({
                        target: ".el-dialog"
                    })
                    const data = new FormData();
                    const { file, remark } = this.actData;
                    data.append("actName", this.actData.actName);
                    data.append("actStartTime", this.actData.actStartTime);
                    data.append("actEndTime", this.actData.actEndTime);
                    data.append("file", file[0]);
                    data.append("remark", remark);
                    return axios.post(this.$baseURL + "api/giftActivity/addGiftActivity", data, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            token: this.$store.state.token
                        }
                    })
                })
                .then(res => {
                    if (res.data.code === 2000) {
                        this.$message({
                            message: '保存成功',
                            type: "success"
                        });
                        loadingRef && loadingRef.close();
                        this.handleClose();
                        this.fetchData()
                    } else if (res.data?.code === 1003) {
                        this.$store.commit("clearUserInfo");
                        this.$message({
                            message: "登录过期，请重新登录",
                            type: "warning"
                        });
                        return Promise.reject(false);

                    } else if (res.code !== 1003) {
                        loadingRef && loadingRef.close();
                        return Promise.reject(res)
                    }
                })
                .catch(reason => {
                    console.warn("上传失败", reason);
                    if (reason !== false) {
                        this.$message({
                            message: reason?.msg || "发放失败",
                            type: "error"
                        })
                    }
                    loadingRef && loadingRef.close();
                })
        },
        handleEditOpen(row) {
            console.log(row);
            this.editDialog = true
            // this.actEditData.actName = row.actName
            // this.actEditData.remark = row.remark
            // this.actEditData.id = row.id
            this.actEditData.cancelRemark = ''

        },
        handleEditClose() {
            this.actEditData.cancelRemark = ''
            this.$refs.form.clearValidate();
            this.editDialog = false;
        },
        handleEditSure() {
            const { checkedlist } = this;
            let loadingRef;
            this.$refs.form.validate().then(() => {
                this.$confirm(`您确认不赠送此部分客户活动劵吗?`, "温馨提示")
                    .then(() => {
                        loadingRef = Loading.service({
                            target: ".editDialog .el-dialog"
                        })
                        return this.$axios({
                            url: "api/giftActivity/batchDeleteGift",
                            method: "post",
                            data: { ids: checkedlist.map(it => it.id).join(), cancelRemark: this.actEditData.cancelRemark }
                        })
                    })
                    .then(res => {
                        if (res.code === 2000) {
                            this.$message({
                                message: "操作成功",
                                type: "success"
                            })
                            loadingRef && loadingRef.close();
                            this.handleEditClose()
                            this.fetchData();
                        } else {
                            this.$message({
                                message: res.msg || "操作失败",
                                type: "error"
                            })
                            loadingRef && loadingRef.close();
                            return Promise.reject(res)
                        }
                    })
                    .catch(reason => {
                        loadingRef && loadingRef.close();
                        if (reason !== "cancel") {
                            this.$message({
                                message: reason.msg || "操作失败",
                                type: "error"
                            })
                        }
                    })
            })



            // let loadingRef;
            // this.$refs.form.validate()
            //     .then(() => {
            //         loadingRef = Loading.service({
            //             target: ".editDialog .el-dialog"
            //         })
            //         const data = this.actEditData
            //         return this.$axios({
            //             url: "api/giftActivity/editGiftActivity",
            //             method: "post",
            //             data
            //         })
            //     })

            //     .then(res => {
            //         if (res.code === 2000) {
            //             this.$message({
            //                 message: "保存成功",
            //                 type: "success"
            //             })
            //             loadingRef && loadingRef.close();
            //             this.fetchData();
            //             this.handleEditClose();
            //         }
            //     })

            //     .catch(reason => {
            //         // this.$refs.form.validate 验证失败的拒绝结果是值 false
            //         // 对于验证失败，不用 message 提示，因为表单控件上已有提示
            //         reason && this.$message({
            //             message: "保存失败",
            //             type: "error"
            //         });
            //         console.warn(reason);
            //     })

            //     .then(() => {
            //         this.posting = false
            //     })
        },
        handleDel(row) {
            this.$confirm(`您确定要删除么?`, "温馨提示")
                .then(() => {
                    this.loading = true;
                    return this.$axios({
                        url: "api/giftActivity/deleteGiftActivity",
                        method: "post",
                        data: { id: row.id }
                    })
                })
                .then(res => {
                    this.loading = false
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        })
                        this.fetchData();
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.loading = false
                    if (reason !== "cancel") {
                        this.$message({
                            message: reason.msg || "删除失败",
                            type: "error"
                        })
                    }
                })
        },



        fetchData() {
            if (this.loading) return;
            this.loading = true;
            let params = this.extractParams(); // 格式化查询参数
            params.actId = this.actId
            params.actId = this.$route.params.id


            console.log(params);
            this.$axios({
                url: "api/giftActivity/queryGiftActivityDetail",
                params
            })
                .then(res => {
                    if (res.code === 2000 && res.data.giftDetailList) {

                        this.tableData = res.data.giftDetailList || []
                        this.total = res.data?.count;

                        this.loading = false;

                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                    this.loading = false;
                })
        },

        clearSP_trim() {
            this.SP_.searchType = 1
        },

        // 批量取消赠送
        handleBulkDelete() {
            this.editDialog = true
        },

        // 点击一键作废
        handleTotalDelete() {
            const data = this.extractParams(true);
            this.$confirm(
                "请精准的筛选条件进行查询劵进行作废",
                "温馨提示",
                {
                    confirmButtonText: '已选择',
                    cancelButtonText: '取消',
                }
            )
                .then(() => {
                    this.loading = true;
                    return this.fetchCount(data)
                })
                .then(count => {
                    this.loading = false;
                    return this.$confirm(
                        `满足条件的优惠共有${count}张, 确定都需要作废么?`,
                        "温馨提示",
                        {
                            confirmButtonText: '确定作废',
                            cancelButtonText: '取消',
                        }
                    )
                })
                .then(() => {
                    this.loading = true;
                    return this.totalDelete(data)
                })
                .then(() => {
                    this.loading = false;
                    this.$message({
                        message: "作废成功",
                        type: "success"
                    })
                    this.fetchData();
                })
                .catch(reason => {
                    if (reason === "cancel") {
                        console.log("用户取消")
                    } else {
                        this.$message({
                            message: reason.msg || "作废失败",
                            type: "error"
                        })
                    }
                    this.loading = false
                })
        },
        // 点击同步员工code
        handleCusCode() {
            // const data = this.extractParams(true);
            this.$confirm(
                "确定同步员工code？",
                "温馨提示",
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }
            )

                .then(() => {
                    this.loading = true;
                    return this.updateCustCouponSaleCode()
                })
                .then(() => {
                    this.loading = false;
                    this.$message({
                        message: "同步成功",
                        type: "success"
                    })
                    this.fetchData();
                })
                .catch(reason => {
                    if (reason === "cancel") {
                        console.log("用户取消")
                    } else {
                        this.$message({
                            message: reason.msg || "同步失败",
                            type: "error"
                        })
                    }
                    this.loading = false
                })
        },


        fetchCount(data) {
            return this.$axios({
                url: "api/custCoupon/queryBatchDeleteCount", method: "post",
                data
            })
                .then(res => {
                    if (res.code === 2000) {
                        return res.data.count;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },

        updateCustCouponSaleCode() {
            return this.$axios({
                url: "api/custCoupon/updateCustCouponSaleCode", method: "post",

            })
                .then(res => {
                    if (res.code === 2000) {
                        return res
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },

        totalDelete(data) {
            return this.$axios({
                url: "api/custCoupon/batchDeleteCustCoupon", method: "post",
                data
            })
                .then(res => {
                    if (res.code === 2000) {
                        return res
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },


        // 点击导出
        handleExportBtnOld() {
            // 检查有无时间条件，没有的话不能导出
            // const {startTime, endTime} = this.SP_;
            // if (!(startTime || endTime)) {
            //     return this.$message({
            //         message: "请选择导出的时间范围",
            //         type: "error"
            //     });
            // }

            this.$confirm("确认导出当前条件下的优惠券数据吗？")

                .then(() => {
                    this.posting = true
                    // const params = {
                    //     ...this.extractParams(true),
                    //     // startTime,
                    //     // endTime
                    // };
                    return this.$axios({
                        url: "api/custCoupon/downCustCoupon",
                        method: "post",
                        data: {
                            // content: JSON.stringify(params)
                            ...this.extractParams(true)
                        }
                    })
                })

                .then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "已加入导出任务，去消息管理的下载通知中查看吧",
                            type: "success"
                        })
                    } else if (res.code !== 1003) return Promise.reject(res)
                })

                .catch(reason => {
                    // this.$confirm拒绝结果是值 cancel
                    if (reason === "cancel") {
                        console.log("取消了")
                    } else {
                        this.$message({
                            message: reason.msg || "导出失败",
                            type: "error"
                        });
                        console.warn("导出失败", reason);
                    }
                })
                .then(() => this.posting = false)
        },
        // 点击导出
        handleExportBtn() {
            this.$confirm("确认导出当前条件下的客户吗？")

                .then(() => {
                    this.posting = true
                    return this.$axios({
                        url: "api/giftActivity/exportGiftCustomerDetail",
                        method: "post",
                        data: {
                            // content: JSON.stringify(params)
                            ...this.extractParams(true),
                            actId: this.actId
                        }
                    })
                })

                .then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "已加入导出任务，去消息管理的下载通知中查看吧",
                            type: "success"
                        })
                    } else if (res.code !== 1003) return Promise.reject(res)
                })

                .catch(reason => {
                    // this.$confirm拒绝结果是值 cancel
                    if (reason === "cancel") {
                        console.log("取消了")
                    } else {
                        this.$message({
                            message: reason.msg || "导出失败",
                            type: "error"
                        });
                        console.warn("导出失败", reason);
                    }
                })
                .then(() => this.posting = false)
        }
    },

    created() {
        console.log(this.$route);
        if (this.$route.params.id) {
            this.actId = Number(this.$route.params.id);
        }

        this.auth.delete = this.$hasAuthFor("api/giftActivity/batchDeleteGift");
        this.auth.export = this.$hasAuthFor("api/giftActivity/exportGiftCustomerDetail");
        // this.auth.export = this.$hasAuthFor("api/custCoupon/downCustCoupon");
        this.auth.batchDelete = this.$hasAuthFor("api/custCoupon/deleteCustCoupon");
        this.auth.updateCustCouponSaleCode = this.$hasAuthFor("api/custCoupon/updateCustCouponSaleCode");
        this.auth.totalDelete = this.$hasAuthFor("api/custCoupon/batchDeleteCustCoupon") &&
            this.$hasAuthFor("api/custCoupon/queryBatchDeleteCount");
    }
};